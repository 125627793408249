import React, { Component, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import './subscription_plans.css'
import axios from 'axios';
import { error } from 'jquery';
import {Card, Button} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

export default function SubscriptionPlans () {
  const navigate = useNavigate();
  const { t } = useTranslation(['home', 'base']);

  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    axios.get('subscription/subscriptions')
      .then(response => {setSubscriptions(response.data); console.log(response.data)})
      .catch((error) => console.log(error))

  }, []);

    return (
      <>
      <div className='subscroptions'>
        {subscriptions.map(s => 
          <>
            <Card>
              <Card.Header>{s.name}</Card.Header>
              <Card.Title>{s.cost} р.</Card.Title>
              <Card.Text>
                Количесство устройств: {s.deviceCount}
              </Card.Text>
              <Button variant="primary" onClick={() => {navigate("/tpay", {state: {cost: s.cost, id: s.id}})}}>Купить</Button>
            </Card>
          </>
          )}
      </div>
      </>
    );
}
