import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import './custom.css';
import Login from './components/Login';
import Register from './components/Register';
import Home from './components/Home';
import TPay from './components/TPay';
import SubscriptionPlans from './components/subscription/SubscriptionPlans';

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <>
      <Layout>
          <Route path='/' component={Home} element={<Home />}/>
          <Route path='/login' component={Login} element={<Login />}/>
          <Route path='/registration' component={Register} element={<Register />}/>
          <Route path='/tpay' component={TPay} element={<TPay />}/>
          <Route path='/plans' component={SubscriptionPlans} element={<SubscriptionPlans />}/>
      </Layout>
      </>
      
    );
  }
}
