import React, {useState, useEffect} from 'react'
import {Form, Button} from "react-bootstrap";
import axios from 'axios';
import {useTranslation} from "react-i18next";
import { error } from 'jquery';

function Login(){
    const { t } = useTranslation(['login', 'base']);
    const [errorInfo, setErrorInfo] = useState(<div></div>);

    useEffect(() => {
        console.log("login mount");
        window.YaAuthSuggest.init(
            {
              client_id: "61d9963b9f1b468aa2240dd0f6873e4e",
              response_type: "token",
              redirect_uri: "https://apcloud.csky.space/login_ya.html"
            },
            "https://apcloud.csky.space",
            {
              view: "button",
              parentId: "ya-id",
              buttonSize: 'm',
              buttonView: 'main',
              buttonTheme: 'light',
              buttonBorderRadius: "13",
              buttonIcon: 'ya',
            }
          )
          .then(({handler}) => handler())
          .then(data => {sendYaToken(data)})
          .catch(error => console.log('Ya error', error))
    }, []);

    function submitForm(event){
        event.preventDefault()
        console.log(event.target[0].value)
        console.log(event.target[1].value)

        axios.post('user/login', { login: event.target[0].value, password: event.target[1].value})
            .then(response => {window.location = "/"})
            .catch((error) => {setErrorInfo(<div style={{color: "red", paddingTop: "10px"}}>{t('login_incorrect')}</div>)});
    }

    function sendYaToken(token) {
        axios.post('yandexauth/ya_login', token)
            .then(response => {window.location = "/"})
            .catch((error) => {})
    }

    return(
        <div className='blocks'>
            <div className='body-box'>
                <h1>{t('Entrance', { ns: "base"})}</h1>
                <Form onSubmit={submitForm}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{t('address')}</Form.Label>
                        <Form.Control type="email" placeholder={t('address_placeholder')} />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>{t('password')}</Form.Label>
                        <Form.Control type="password" placeholder={t('password_placeholder')} />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        {t('Enter', { ns: "base"})}
                    </Button>
                </Form>
                <div style={{marginTop: "10px"}}>
                    <a href='/forgot-pass'>{t('forgot_pass')}</a>
                </div>
                <div style={{marginTop: "10px"}}>
                    {t('no_account')} <a href='/registration'>{t('register')}</a>
                </div>
                {errorInfo}
            </div>
            <div className='ya-auth' id='ya-id' style={{marginTop:"10px"}}></div>
        </div>
    )
}

export default Login;