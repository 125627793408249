import React, { useState } from 'react'
import {Form, Button} from "react-bootstrap";
import axios from 'axios';
import {useTranslation} from "react-i18next";

function Register(){
    const [errorInfo, setErrorInfo] = useState(<div></div>);

    const { t } = useTranslation(['register', 'base']);

    function submitForm(event){
        event.preventDefault()
        console.log(event.target[0].value)
        console.log(event.target[1].value)

        axios.post('user/register', { email: event.target[0].value, password: event.target[1].value})
            .then(response => {console.log(response) ; window.location = "/confirm-email"})
            .catch((error) => {
                console.log(error.response);
                setErrorInfo(<div style={{color: "red", paddingTop: "10px"}}>Such an email already exists</div>)
            });
    }

    return(
        <div className='blocks'>
            <div className='body-box'>
            <h1>{t('Register', { ns: "base"})}</h1>
            <Form onSubmit={submitForm}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>{t('address', { ns: "register"})}</Form.Label>
                <Form.Control type="email" placeholder="Enter email" />
                <Form.Text className="text-muted">
                
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>{t('password', { ns: "register"})}</Form.Label>
                <Form.Control type="password" placeholder="Password" />
            </Form.Group>
            <Button variant="primary" type="submit">
                {t('Register', { ns: "base"})}
            </Button>
            </Form>
            {errorInfo}
            </div>
        </div>
    )
}

export default Register;
