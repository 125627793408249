import React, {Component, useEffect, useState} from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import {Form, NavDropdown} from "react-bootstrap";
import { Link } from 'react-router-dom';
import './NavMenu.css';
import axios from 'axios';
import { useTranslation } from "react-i18next";

export default function NavMenu ()  {
  const { t } = useTranslation(['nav_menu']);

  const displayName = NavMenu.name;
  const [collapsed, setCollapsed] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [login, setLogin] = useState('');
  const [fullName, setFullName] = useState('');
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    axios.get('user/start')
      .then(response => {
        setIsLogin(true);
        setLogin(response.data.email);
        setIsAdmin(response.data.isAdmin);
        setAmount(response.data.amount);
        console.log(response);
      })
      .catch((error) => {console.log(error.response)});
    }, [window.location.href]);


    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
          <NavbarBrand tag={Link} to="/">APCloud</NavbarBrand>
          {/*<NavbarToggler onClick={this.toggleNavbar} className="mr-2" />*/}
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={false} navbar>
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/plans">Тарифы</NavLink>
              </NavItem>
              {
                isLogin ? 
                <>
                  <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/tpay">{amount}р</NavLink>
                  </NavItem>
                  <NavDropdown
                    id="nav-dropdown-white-example"
                    title={login}
                    menuVariant="light"
                  >
                    {/*<NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="user/logout">{t('logout')}</NavDropdown.Item>
                  </NavDropdown>
                </> : 
                <>
                  <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/login">Login</NavLink>
                  </NavItem>
                </>
              }
              
            </ul>
          </Collapse>
        </Navbar>
      </header>
    );
}
