import React, { Component, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import './TPay.css'
import axios from 'axios';
import { error } from 'jquery';
import {Card, Button} from 'react-bootstrap'
import { useLocation } from 'react-router-dom';

export default function TPay () {
  const { state } = useLocation();
  const { t } = useTranslation(['home', 'base']);

  const [errorInfo, setErrorInfo] = useState(<div></div>);
  const [orderId, setOrderId] = useState("");
  const [amount, setAmount] = useState(state.cost);
  const [subscriptionId, setSubscriptionId] = useState(state.id);
  const [login, setLogin] = useState("");
  const [fullName, setFullName] = useState('123');

  useEffect(() => {
    const script = document.createElement('script');
    console.log("state: ")
    console.log(state);
  
    script.src = "https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js";
    script.async = true;
  
    document.body.appendChild(script);

    axios.get('user/start')
      .then(response => {
        setLogin(response.data.email);
        setFullName(response.data.fullName);
      })
      .catch((error) => {console.log(error.response)});

    axios.post('tpayment/createOrder', { amount: amount, subscriptionId: subscriptionId })
      .then(response => {setOrderId(response.data.orderId)})
      .catch((error) => {setErrorInfo(<div style={{color: "red", paddingTop: "10px"}}>Ошибка</div>)});
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  function tpay() {
    console.log("tsubmit" + orderId);
    var element = document.getElementById("payform-tinkoff");
    console.log(element)
    window["pay"](element)
    console.log("pay")
  }

  function createOrder(e) {
    e.preventDefault();
    tpay()
    /*axios.post('tpayment/createOrder', { amount: amount })
        .then(response => {setOrderId(response.data.orderId)})
        .catch((error) => {setErrorInfo(<div style={{color: "red", paddingTop: "10px"}}>Ошибка</div>)});*/
  }

  function changeName(e){
    setFullName(e.target.value);
  }

    return (
      <>

      <div>
        <form className="payform-tinkoff" name="payform-tinkoff" id="payform-tinkoff" onSubmit={createOrder}>
            <input className="payform-tinkoff-row" type="hidden" name="terminalkey" readOnly value="1715006489330DEMO"/>
            <input className="payform-tinkoff-row" type="hidden" name="frame" readOnly value="false"/>
            <input className="payform-tinkoff-row" type="hidden" name="language" readOnly value="ru"/>
            <input className="payform-tinkoff-row" type="text" placeholder="Сумма заказа" name="amount" readOnly value={amount} required/>
            <input className="payform-tinkoff-row" type="hidden" placeholder="Номер заказа" name="order" readOnly value={orderId}/>
            <input className="payform-tinkoff-row" type="text" placeholder="Описание заказа" name="description" readOnly value="Тестовое Описание"/>
            <input className="payform-tinkoff-row" type="text" placeholder="ФИО плательщика" name="name" onChange={changeName} value={fullName}/>
            <input className="payform-tinkoff-row" type="email" placeholder="E-mail" name="email" readOnly value={login}/>
            <input className="payform-tinkoff-row" type="tel" placeholder="Контактный телефон" name="phone"/>
            <input className="payform-tinkoff-row payform-tinkoff-btn" type="submit" value="Оплатить"/>
        </form>
        {errorInfo}
      </div>
      </>
    );
}
